
export const rules = {
    _required: {
        validation: "_required",
        validationMessages: {_required: "Ce champs ne peut être vide"},
        "label-class": ["required-field-label"]
    },
    _validUniqueSelect: {
        validation: "_validUniqueSelect",
        validationMessages: {_validUniqueSelect: "Vous devez choisir une option"},
        "label-class": ["required-field-label"]
    },
    _validDate: {
        validation: "_validDate",
        validationMessages: {_validDate: "La date est invalide"},
    },
    // false = error
    // true = valid
    configRulesVue: {
        _required: v => v.value !== '',
        _validUniqueSelect: v => v.value !== '',
        _validDate: (v) => /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(v.value) || v.value === "",
    },
    // validCapital: v => (lenBetween(v, 1, 18) && /\.\d\d$/.test(v) ? lenBetween(v, 1, 16) : v !== null) || "Le capital est invalide",
    // validSiren: v => v !== null && v.length === 11 || "Le SIREN est invalide",
    // validDuration: v => (!isNaN(v) && v > 0 && v < 100) || "La durée est invalide",
    // validCodePostal: v => /^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/.test(v) || "Le code postal est invalide",
};