import Vue from 'vue'
import VueRouter from 'vue-router'
import App from "./App";
import VueFormulateExtended from "vue-formulate-extended";


import {rules} from "./data/rules";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.config.productionTip = false

Vue.use(VueRouter);
const VueFormulate = require('@braid/vue-formulate');
Vue.use(VueFormulate.default, {
  rules: rules.configRulesVue,
  plugins: [
    VueFormulateExtended({
      features: {
        textMask: true
      }
    })
  ]
});


import "./design/formulate.css"


const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: () => import('./components/forms') },
    { path: '/form', name: '/form', component: () => import('./components/form'), props: true },
  ]
});

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')