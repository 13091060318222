<template>
  <div id="App">
    <RouterView />
  </div>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    //
  }),
};
</script>

<style>
</style>
